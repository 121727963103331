// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../components/protected-route/ProtectedRoute';
import { integrationsRoutes } from '../../routers/route-names';
import FhirConfigurationComponent from './containers/fhir-configuration/FhirConfigurationContainer';
import Hl7SystemConfigurationComponent from './containers/hl7-configuration/containers/hl7-system/Hl7SystemConfigurationContainer';
import Hl7RuleConfigurationComponent from './containers/hl7-configuration/containers/hl7-rule/Hl7RuleConfigurationContainer';
import Hl7ConfigurationComponent from './containers/hl7-configuration/Hl7ConfigurationContainer';
import Hl7MessagesComponent from './containers/hl7-configuration/containers/hl7-messages/Hl7MessagesContainer';
import DataImportComponent from './containers/data-import/DataImportContainer';
import getConfig from '../../../src/appConfigUtils';

const IntegrationsRoutes: React.FC = () => {
  return (
    <Switch>
      {/* <ProtectedRoute
        path={integrationsRoutes.EMR_CONFIGURATION}
        component={EmrConfigurationComponent}
      /> */}
      <ProtectedRoute
        exact
        path={integrationsRoutes.FHIR_CONFIGURATION}
        component={FhirConfigurationComponent}
      />
      <ProtectedRoute
        exact
        path={integrationsRoutes.HL7_CONFIGURATION}
        component={Hl7ConfigurationComponent}
      />
      <ProtectedRoute
        exact
        path={integrationsRoutes.HL7_SYSTEM_CONFIGURATION}
        component={Hl7SystemConfigurationComponent}
      />
      <ProtectedRoute
        exact
        path={integrationsRoutes.HL7_RULE_CONFIGURATION}
        component={Hl7RuleConfigurationComponent}
      />
      <ProtectedRoute
        exact
        path={integrationsRoutes.HL7_MESSAGES}
        component={Hl7MessagesComponent}
      />
      {getConfig().DATA_IMPORT_ENABLED && (
        <ProtectedRoute
          exact
          path={integrationsRoutes.DATA_IMPORT}
          component={DataImportComponent}
        />
      )}
    </Switch>
  );
};

export default IntegrationsRoutes;

// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import type {
  ActivityHistoryResponseDecrypted,
  ApiError,
  PatientDecrypted,
  PatientDemographicHistoryDecrypted,
  PatientSearchResponseDecrypted,
  PatientHl7MessageSummaryDecrypted,
} from '../../../api/types';
import type { PatientSearchRequestDecrypted } from '../types';

export const patientSearchActions = createAsyncAction(
  'patient/search/PATIENT_SEARCH_REQUEST',
  'patient/search/PATIENT_SEARCH_SUCCESS',
  'patient/search/PATIENT_SEARCH_FAILURE',
  'patient/search/PATIENT_SEARCH_CANCEL',
)<
  PatientSearchRequestDecrypted,
  PatientSearchResponseDecrypted,
  ApiError,
  string | void
>();

export const fetchDemographicsHistoryActions = createAsyncAction(
  'patient/fetch/DEMOGRAPHICS_HISTORY_REQUEST',
  'patient/fetch/DEMOGRAPHICS_HISTORY_SUCCESS',
  'patient/fetch/DEMOGRAPHICS_HISTORY_FAILURE',
  'patient/fetch/DEMOGRAPHICS_HISTORY_CANCEL',
)<string | void, PatientDemographicHistoryDecrypted, ApiError>();

export const clearPatientSearchAction = createAction(
  'patient/search/CLEAR_PATIENT_SEARCH',
)<void>();

export const clearPatientDetailsAction = createAction(
  'patient/details/CLEAR_PATIENT_DETAILS',
)<void>();

export const selectPatientAction = createAction(
  'patient/search/SELECT_PATIENT',
)<PatientDecrypted>();

export const patientActivityHistoryActions = createAsyncAction(
  'patient/details/PATIENT_ACTIVITY_HISTORY_REQUEST',
  'patient/details/PATIENT_ACTIVITY_HISTORY_SUCCESS',
  'patient/details/PATIENT_ACTIVITY_HISTORY_FAILURE',
  'patient/details/PATIENT_ACTIVITY_HISTORY_CANCEL',
)<string, ActivityHistoryResponseDecrypted, ApiError, string | void>();

export const patientHl7MessagesActions = createAsyncAction(
  'patient/details/PATIENT_HL7_MESSAGES_REQUEST',
  'patient/details/PATIENT_HL7_MESSAGES_SUCCESS',
  'patient/details/PATIENT_HL7_MESSAGES_FAILURE',
  'patient/details/PATIENT_HL7_MESSAGES_CANCEL',
)<
  {
    patientId: string;
    patientCreatedTimestamp: moment.Moment;
    pageNumber: number;
    pageSize: number;
  },
  PatientHl7MessageSummaryDecrypted[],
  ApiError,
  string | void
>();

// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, switchMap, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../../../i18n';
import {
  configurationGetActions,
  configurationSaveActions,
} from './actions';
import { getErrorMessageCode } from '../../../../../i18n/utils';
import {
  startGetConfigurationSpan,
  endGetConfigurationSpan,
  startSaveConfigurationSpan,
  endSaveConfigurationSpan,
} from '../../../tracing';
import { showErrorBannerAction } from '../../../../../store/error-banner-state/actions';
import { errors } from '../../../../../store/error-banner-state/errors';
import {
  getConfiguration$,
  saveConfiguration$,
} from '../../../../../api/services/ageLimitConfigurationService';

export const saveConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(configurationSaveActions.request)),
    tap(() => {
      startSaveConfigurationSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        saveConfiguration$(payload),
      ).pipe(
        tap(() => {
          endSaveConfigurationSpan();
        }),
        map(() => configurationSaveActions.success()),
        catchError(error => {
          endSaveConfigurationSpan(error);
          return of(
            configurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.CONFIGURATION,
                'configuration-save-failed',
              ),
              message:
                'An error occurred while saving the configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.CONFIGURATION_SAVE_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const getConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(configurationGetActions.request)),
    tap(() => {
      startGetConfigurationSpan();
    }),
    switchMap(() => {
      return from(getConfiguration$()).pipe(
        tap(() => {
          endGetConfigurationSpan();
        }),
        map(configuration =>
          configurationGetActions.success(configuration),
        ),
        catchError(error => {
          endGetConfigurationSpan(error);
          return of(
            configurationGetActions.failure({
              code: getErrorMessageCode(
                ContextNames.CONFIGURATION,
                'configuration-get-failed',
              ),
              message:
                'An error occurred while retrieving the configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.CONFIGURATION_GET_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const configurationEpic = combineEpics(
  saveConfigurationEpic,
  getConfigurationEpic,
);

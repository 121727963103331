// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { RootState } from '../../../../../store/rootReducer';
import { Configuration, ConfigurationRangeConstants } from '../types';

export const isLoadingSelector = ({ configuration }: RootState): boolean =>
  configuration.loading;

export const configurationSelector = ({
  configuration,
}: RootState): Configuration | null => configuration.configuration;

export const configurationRangeConstantsSelector = ({
  configuration,
}: RootState): ConfigurationRangeConstants | null => configuration.configurationRangeConstants;

export const errorSelector = ({
  configuration,
}: RootState): string | null => configuration.error;

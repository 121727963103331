// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageSubHeader from '../../../../components/page-sub-header/PageSubHeader';
import SetTitle from '../../../../utils/DynamicTitleHelper';
import { Col, Form, Row, Space, Spin, Typography } from 'antd';
import ContentCard from '../../../../components/content-card/ContentCard';
import { Button, ButtonVariant, InputBox } from '@imprivata-cloud/components';
import SaveButton from '../../../../components/action-bar/SaveButton';
import classes from './DataImportContainer.module.less';
import { useDispatch } from 'react-redux';
import { useGetDataImportConfiguration } from './store/hooks';
import { saveDataImportConfiguration } from './store/facades';
import SaveDiscardModal from '../../../../components/save-discard-modal/SaveDiscardModal';
import { URL_REGEX } from '../../constants';
import EmptyStateBlueSvg from '../../../../assets/svg/empty-state-blue.svg?react';
import clsx from 'clsx';
import Icon from '@ant-design/icons';
import editIcon from '../../../../assets/svg/edit.svg?react';
import addIcon from '../../../../assets/svg/add.svg?react';
import CancelButton from '../../../../components/action-bar/CancelButton';

const requiredMsgKey = 'data-import-configuration.required';
const invalidUrlMsgKey = 'data-import-configuration.invalid-url';

const DataImportComponent: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [reset, setReset] = useState(false);

  const { dataImportConfiguration, isLoading } =
    useGetDataImportConfiguration();

  SetTitle(t('data-import-configuration.title'));

  const validateAndUpdate = () => {
    setIsDirty(false);

    const clientIdValue = (form.getFieldValue('clientId') || '').replace(
      /\s+/g,
      '',
    );
    let interconnectInstanceBaseURL = (
      form.getFieldValue('interconnectInstanceBaseURL') || ''
    ).replace(/\s+/g, '');

    form.setFieldsValue({
      clientId: clientIdValue,
      interconnectInstanceBaseURL: interconnectInstanceBaseURL,
    });

    form.validateFields().then(() => {
      saveDataImportConfiguration(
        {
          clientId: clientIdValue,
          fhirServerBaseUrl: interconnectInstanceBaseURL,
        },
        dispatch,
      );

      setIsSaveDisabled(true);
      setEditModeEnabled(false);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      form.setFieldsValue(
        setFormData(
          dataImportConfiguration?.clientId,
          dataImportConfiguration?.fhirServerBaseUrl,
        ),
      );
    }
    setReset(!reset);
    setIsDirty(false);
    setIsSaveDisabled(true);
    setEditModeEnabled(false);
  };

  const handleChange = () => {
    setIsSaveDisabled(false);

    form.validateFields().catch(() => {
      setIsSaveDisabled(true);
    });

    if (!hasConfigurationChanged()) {
      setIsDirty(false);
      setIsSaveDisabled(true);
    } else {
      setIsDirty(true);
    }
  };

  const hasConfigurationChanged = () =>
    form.getFieldValue('clientId') !== dataImportConfiguration?.clientId ||
    form.getFieldValue('interconnectInstanceBaseURL') !==
      dataImportConfiguration?.fhirServerBaseUrl;

  useEffect(() => {
    form.setFieldsValue(
      setFormData(
        dataImportConfiguration?.clientId,
        dataImportConfiguration?.fhirServerBaseUrl,
      ),
    );
  }, [dataImportConfiguration, form]);

  const setFormData = (
    clientId: string | undefined,
    interconnectInstanceBaseURL: string | undefined,
  ) => {
    return {
      clientId,
      interconnectInstanceBaseURL,
    };
  };

  return (
    <>
      <PageSubHeader title={t('data-import-configuration.title')} />
      <SaveDiscardModal
        title={t('data-import-configuration.save-discard-modal.title')}
        cancelText={t('data-import-configuration.save-discard-modal.discard')}
        okText={t('actions.save')}
        content={t('data-import-configuration.save-discard-modal.content')}
        open={!isSaveDisabled}
        onSave={() => validateAndUpdate()}
        onDiscard={() => resetForm()}
      ></SaveDiscardModal>
      {isLoading ? (
        <div className={classes.spinner}>
          <Spin size="large" />
        </div>
      ) : (
        <Space
          className={classes.configurationSpace}
          direction="vertical"
          size={4}
        >
          <Row>
            <ContentCard className={classes.configurationCard}>
              <Typography.Title level={3} className={classes.noMarginBottom}>
                {t('data-import-configuration.configuration-subtitle')}
              </Typography.Title>
              {editModeEnabled || !dataImportConfiguration ? (
                <Form
                  form={form}
                  layout="vertical"
                  name="register"
                  onChange={handleChange}
                >
                  <Row gutter={10} wrap={true} style={{ marginTop: '8px' }}>
                    <Col flex="0.4">
                      <Form.Item
                        data-testid="data-import-configuration-form--client-id-label"
                        name="clientId"
                        label={t('data-import-configuration.client-id')}
                        className={classes.noMarginBottom}
                        rules={[
                          {
                            required: true,
                            message: t(requiredMsgKey),
                          },
                        ]}
                      >
                        <InputBox
                          data-testid="data-import-configuration-form--client-id"
                          size="small"
                          type="text"
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="0.4">
                      <Form.Item
                        data-testid="data-import-configuration-form--interconnect-instance-base-url-label"
                        name="interconnectInstanceBaseURL"
                        label={t(
                          'data-import-configuration.interconnect-instance-base-url',
                        )}
                        rules={[
                          {
                            required: true,
                            message: t(requiredMsgKey),
                          },
                          {
                            pattern: URL_REGEX,
                            message: t(invalidUrlMsgKey),
                          },
                        ]}
                      >
                        <InputBox
                          data-testid="data-import-configuration-form--interconnect-instance-base-url"
                          size="small"
                          type="text"
                        />
                      </Form.Item>
                    </Col>
                    <Col flex="0.2" className={classes.editButtonsContainer}>
                      <CancelButton
                        className={classes.cancelButton}
                        onClick={() => resetForm()}
                        disabled={!isDirty}
                        key="cancel-button"
                      />
                      <SaveButton
                        className={classes.saveButton}
                        onClick={() => validateAndUpdate()}
                        disabled={isSaveDisabled}
                        key="save-button"
                      ></SaveButton>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Row
                  gutter={10}
                  wrap={true}
                  className={classes.dataImportConfigurationRow}
                >
                  <Col
                    flex="0.4"
                    className={classes.dataImportConfigurationCol}
                  >
                    <Typography.Text
                      strong
                      data-testid="data-import-configuration--client-id-label"
                    >
                      {t('data-import-configuration.client-id')}
                    </Typography.Text>
                    <Typography.Text data-testid="data-import-configuration--client-id">
                      {dataImportConfiguration?.clientId}
                    </Typography.Text>
                  </Col>
                  <Col
                    flex="0.4"
                    className={classes.dataImportConfigurationCol}
                  >
                    <Typography.Text
                      strong
                      data-testid="data-import-configuration--interconnect-instance-base-url-label"
                    >
                      {t(
                        'data-import-configuration.interconnect-instance-base-url',
                      )}
                    </Typography.Text>
                    <Typography.Text data-testid="data-import-configuration--interconnect-instance-base-url">
                      {dataImportConfiguration?.fhirServerBaseUrl}
                    </Typography.Text>
                  </Col>
                  <Col flex="0.2" className={classes.editButtonContainer}>
                    <Button
                      data-testid="data-import-configuration--edit-btn"
                      variant={ButtonVariant.PRIMARY}
                      onClick={() => setEditModeEnabled(true)}
                      icon={<Icon component={editIcon} />}
                      label={t('actions.edit')}
                    />
                  </Col>
                </Row>
              )}
            </ContentCard>
          </Row>
          <ContentCard
            className={clsx(classes.configurationCard, classes.jobsCard)}
          >
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <Typography.Title level={3} className={classes.noMarginBottom}>
                  {t('data-import-configuration.jobs-subtitle')}
                </Typography.Title>
              </Col>
              <Col>
                <Button
                  data-testid="data-import-configuration--create-new-job-btn"
                  variant={ButtonVariant.SECONDARY}
                  icon={<Icon component={addIcon} />}
                  className={clsx(classes.noBorderButton, 'secondary')}
                  id="edit-button"
                  label={t('data-import-configuration.create-new-job-btn')}
                  disabled
                ></Button>
              </Col>
            </Row>
            <Space className={classes.emptyStateSpace}>
              <EmptyStateBlueSvg />
              <Typography.Title level={2} className={classes.emptyStateTitle}>
                {t('data-import-configuration.no-data-import-jobs--title')}
              </Typography.Title>
              <Typography.Text>
                {t(
                  'data-import-configuration.no-data-import-jobs--description',
                )}
              </Typography.Text>
              <Button
                data-testid="data-import-configuration--create-job-btn"
                variant={ButtonVariant.PRIMARY}
                label={t('data-import-configuration.create-job-btn')}
                disabled
              />
            </Space>
          </ContentCard>
        </Space>
      )}
    </>
  );
};

export default DataImportComponent;

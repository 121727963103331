// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Dispatch } from 'react';
import {
  configurationSaveActions,
} from './actions';
import { SaveConfigRequest } from '@imprivata-cloud/adminapi-client';

export const saveConfiguration = (
  request: SaveConfigRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(
    configurationSaveActions.request(request),
  );
};
// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SaveDataImportConfigurationRequest } from '@imprivata-cloud/adminapi-client';
import type { Dispatch } from 'react';
import {
  dataImportConfigurationGetActions,
  dataImportConfigurationSaveActions,
} from './actions';

export const saveDataImportConfiguration = (
  request: SaveDataImportConfigurationRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(dataImportConfigurationSaveActions.request(request));
};

export const getDataImportConfiguration = (dispatch: Dispatch<any>): void => {
  dispatch(dataImportConfigurationGetActions.request());
};

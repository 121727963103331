// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';

import type { ApiError } from '../../../../../api/types';
import { GetConfigurationResponse, SaveConfigRequest } from '@imprivata-cloud/adminapi-client';

export const configurationGetActions = createAsyncAction(
  'settings/configuration/GET_CONFIGURATION_REQUEST',
  'settings/configuration/GET_CONFIGURATION_SUCCESS',
  'settings/configuration/GET_CONFIGURATION_FAILURE',
  'settings/configuration/GET_CONFIGURATION_CANCEL',
)<void, GetConfigurationResponse, ApiError, string | void>();

export const configurationSaveActions = createAsyncAction(
  'settings/configuration/SAVE_CONFIGURATION_REQUEST',
  'settings/configuration/SAVE_CONFIGURATION_SUCCESS',
  'settings/configuration/SAVE_CONFIGURATION_FAILURE',
  'settings/configuration/SAVE_CONFIGURATION_CANCEL',
)<
  SaveConfigRequest,
  void,
  ApiError,
  string | void
>();

// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  tracer,
  SpanNames,
  recordException,
  startRootSpan,
  endRootSpan,
} from '../../tracing';

export function startGetConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_CONFIGURATION);
  endRootSpan();
}

export function startSaveConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SAVE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endSaveConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SAVE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SAVE_CONFIGURATION);
  endRootSpan();
}
// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import type { Dispatch, SetStateAction } from 'react';
import { getPathWithQuery } from '../../../utils/routingHelpers';
import { MenuItemKeys } from '../../../components/nav-menu/MenuItemKeys';
import {
  DASHBOARD_ROUTE,
  HELP_ROUTE,
  INSTALLERS_ROUTE,
  LOGOUT_ROUTE,
  NOT_FOUND_ROUTE,
  PATIENT_SEARCH_ROUTE,
  PATIENT_DETAILS_ROUTE,
  SETTINGS_ROUTE,
  integrationsRoutes,
  reportsRoutes,
} from '../../../routers/route-names';

const routesMap: Record<string, string> = {
  [MenuItemKeys.Dashboard]: DASHBOARD_ROUTE,
  [MenuItemKeys.Installers]: INSTALLERS_ROUTE,
  [MenuItemKeys.Patients]: PATIENT_SEARCH_ROUTE,
  [MenuItemKeys.ActivityExtract]: reportsRoutes.ACTIVITY_EXTRACT,
  [MenuItemKeys.AuditExtract]: reportsRoutes.AUDIT_EXTRACT,
  [MenuItemKeys.Logout]: LOGOUT_ROUTE,
  [MenuItemKeys.FhirConfiguration]: integrationsRoutes.FHIR_CONFIGURATION,
  [MenuItemKeys.Hl7Configuration]: integrationsRoutes.HL7_CONFIGURATION,
  [MenuItemKeys.DataImport]: integrationsRoutes.DATA_IMPORT,
  [MenuItemKeys.Settings]: SETTINGS_ROUTE,
  [MenuItemKeys.Help]: HELP_ROUTE,
};

export function getActiveItemFromPath(path: string): string | undefined {
  if (path === DASHBOARD_ROUTE) {
    return MenuItemKeys.Dashboard;
  } else if (path === PATIENT_DETAILS_ROUTE) {
    return MenuItemKeys.Patients;
  }
  const pathParts = path.split('/').slice(1);
  return Object.keys(routesMap).find(key => {
    const route = routesMap[key];

    return pathParts[0] === route.slice(1);
  });
}

export function useSiteLayoutNavigation(): [
  string,
  Dispatch<SetStateAction<string>>,
  (item: string) => void,
] {
  const location = useLocation();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState<string>(
    getActiveItemFromPath(location.pathname) ?? MenuItemKeys.Dashboard,
  );
  const handleClick = useCallback(
    (item: string) => {
      const routePath = routesMap[item] ?? NOT_FOUND_ROUTE;

      setActiveItem(item);
      history.push(getPathWithQuery(routePath));
    },
    [history],
  );

  return [activeItem, setActiveItem, handleClick];
}

// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type {
  SaveHl7SystemConfigurationRequest,
  SaveHl7RuleConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import {
  erroredMessagesCountSelector,
  erroredMessagesLoadingSelector,
  isLoadingSelector,
  messageSearchLoadingSelector,
  messageSearchResultsSelector,
  searchResultsCountSelector,
  ruleConfigurationSelector,
  systemConfigurationSelector,
  unsentMessagesCountSelector,
  unsentMessagesLoadingSelector,
  successMessagesCountSelector,
  successMessagesLoadingSelector,
} from './selectors';
import {
  hl7SystemConfigurationGetActions,
  hl7RuleConfigurationGetActions,
  hl7SystemConfigurationSaveActions,
  hl7RuleConfigurationSaveActions,
  hl7ErroredMessageCountActions,
  hl7MessageSearchActions,
  hl7UnsentMessageCountActions,
  hl7SuccessMessageCountActions,
} from './actions';
import { getPathWithQuery } from '../../../../../utils/routingHelpers';

export const useGetHl7SystemConfiguration = (): {
  systemConfiguration: ReturnType<typeof systemConfigurationSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const systemConfiguration = useSelector(systemConfigurationSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(hl7SystemConfigurationGetActions.request());

      return () => {
        dispatch(hl7SystemConfigurationGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return { systemConfiguration, isLoading: loading };
};

export const useSaveHl7SystemConfiguration = (
  request: SaveHl7SystemConfigurationRequest,
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hl7SystemConfigurationSaveActions.request(request));
  }, [dispatch, request]);
};

export const useGetHl7RuleConfigurations = (): {
  ruleConfigurations: ReturnType<typeof ruleConfigurationSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const ruleConfigurations = useSelector(ruleConfigurationSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(hl7RuleConfigurationGetActions.request());

      return () => {
        dispatch(hl7RuleConfigurationGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return { ruleConfigurations, isLoading: loading };
};

export const useSaveHl7RuleConfiguration = (
  request: SaveHl7RuleConfigurationRequest,
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hl7RuleConfigurationSaveActions.request(request));
  }, [dispatch, request]);
};

export const useGetSuccessHl7MessagesCount = (
  startDate: moment.Moment,
): {
  count: ReturnType<typeof successMessagesCountSelector>;
  isLoading: ReturnType<typeof successMessagesLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const count = useSelector(successMessagesCountSelector);
  const isLoading = useSelector(successMessagesLoadingSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(hl7SuccessMessageCountActions.request(startDate));

      return () => {
        dispatch(hl7SuccessMessageCountActions.cancel());
      };
    }, []);
  useMountEffect();

  return {
    count,
    isLoading,
  };
};

export const useGetErroredHl7MessagesCount = (
  startDate: moment.Moment,
): {
  count: ReturnType<typeof erroredMessagesCountSelector>;
  isLoading: ReturnType<typeof erroredMessagesLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const count = useSelector(erroredMessagesCountSelector);
  const isLoading = useSelector(erroredMessagesLoadingSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(hl7ErroredMessageCountActions.request(startDate));

      return () => {
        dispatch(hl7ErroredMessageCountActions.cancel());
      };
    }, []);
  useMountEffect();

  return {
    count,
    isLoading,
  };
};

export const useGetUnsentHl7MessagesCount = (
  startDate: moment.Moment,
): {
  count: ReturnType<typeof unsentMessagesCountSelector>;
  isLoading: ReturnType<typeof unsentMessagesLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const count = useSelector(unsentMessagesCountSelector);
  const isLoading = useSelector(unsentMessagesLoadingSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(hl7UnsentMessageCountActions.request(startDate));

      return () => {
        dispatch(hl7UnsentMessageCountActions.cancel());
      };
    }, []);
  useMountEffect();

  return {
    count,
    isLoading,
  };
};

export const useHl7MessageSearch = (): {
  results: ReturnType<typeof messageSearchResultsSelector>;
  isLoading: ReturnType<typeof messageSearchLoadingSelector>;
  counts: ReturnType<typeof searchResultsCountSelector>;
  filters: {
    dateRange: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    };
    statuses: string[];
    pageNumber: number;
    pageSize: number;
    patientId: string | null;
  };
  updateSearchFilters: (filters: {
    dateRange: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    };
    statuses: string[];
    pageNumber: number;
    pageSize: number;
    patientId: string | null;
  }) => void;
} => {
  const startDateParam = 'startDate';
  const endDateParam = 'endDate';
  const statusParam = 'status';
  const pageNumberParam = 'pageNumber';
  const pageSizeParam = 'pageSize';
  const patientId = 'patientId';

  const defaultPageSize = 25;
  const defaultPageNumber = 1;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const results = useSelector(messageSearchResultsSelector);
  const isLoading = useSelector(messageSearchLoadingSelector);
  const counts = useSelector(searchResultsCountSelector);

  const getDateRangeFromQuery = (
    startDateString: string | null,
    endDateString: string | null,
  ) => {
    const startMoment = moment(startDateString);
    const endMoment = moment(endDateString);
    const startDate = startMoment.isValid()
      ? startMoment
      : moment().startOf('day');
    const endDate = endMoment.isValid() ? endMoment : moment();

    return {
      startDate,
      endDate,
    };
  };

  const getStatusesFromQuery = (statuses: string[]) => {
    const validStatuses = ['success', 'error', 'unsent'];

    return statuses.filter(status =>
      validStatuses.includes(status.toLowerCase()),
    );
  };

  const getPageNumberFromQuery = (pageNumberParam: string | null) => {
    if (!pageNumberParam || isNaN(+pageNumberParam) || +pageNumberParam < 1) {
      return defaultPageNumber;
    }

    return +pageNumberParam;
  };

  const getPageSizeFromQuery = (pageSizeParam: string | null) => {
    if (!pageSizeParam || isNaN(+pageSizeParam) || +pageSizeParam < 1) {
      return defaultPageSize;
    }

    return +pageSizeParam;
  };

  const initialSearchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<{
    dateRange: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    };
    statuses: string[];
    pageNumber: number;
    pageSize: number;
    patientId: string | null;
  }>({
    dateRange: getDateRangeFromQuery(
      initialSearchParams.get(startDateParam),
      initialSearchParams.get(endDateParam),
    ),
    statuses: getStatusesFromQuery(initialSearchParams.getAll(statusParam)),
    pageNumber: getPageNumberFromQuery(
      initialSearchParams.get(pageNumberParam),
    ),
    pageSize: getPageSizeFromQuery(initialSearchParams.get(pageSizeParam)),
    patientId: initialSearchParams.get(patientId)
  });

  useEffect(() => {
    dispatch(
      hl7MessageSearchActions.request({
        startDate: filters.dateRange.startDate,
        endDate: filters.dateRange.endDate,
        messageStatuses: filters.statuses,
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
        patientId: filters.patientId
      }),
    );

    return () => {
      dispatch(hl7MessageSearchActions.cancel());
    };
  }, [filters, dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {
      dateRange: getDateRangeFromQuery(
        searchParams.get(startDateParam),
        searchParams.get(endDateParam),
      ),
      statuses: getStatusesFromQuery(searchParams.getAll(statusParam)),
      pageNumber: getPageNumberFromQuery(searchParams.get(pageNumberParam)),
      pageSize: getPageSizeFromQuery(searchParams.get(pageSizeParam)),
      patientId: initialSearchParams.get(patientId)
    };

    setFilters(filters);
  }, [location]);

  const updateSearchFilters = (filters: {
    dateRange: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    };
    statuses: string[];
    pageNumber: number;
    pageSize: number;
    patientId: string | null;
  }) => {
    history.push(
      getPathWithQuery('', [
        `${startDateParam}=${filters.dateRange.startDate.toISOString()}`,
        `${endDateParam}=${filters.dateRange.endDate.toISOString()}`,
        ...(filters.statuses.length
          ? filters.statuses.map(status => `${statusParam}=${status}`)
          : []),
        `${pageNumberParam}=${filters.pageNumber}`,
        `${pageSizeParam}=${filters.pageSize}`,
        `${patientId}=${filters.patientId ?? ''}`,
      ]),
    );
  };

  return {
    results,
    isLoading,
    counts,
    filters,
    updateSearchFilters,
  };
};

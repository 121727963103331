// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';
import type {
  DataImportConfiguration,
  SaveDataImportConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import type { ApiError } from '../../../../../api/types';

export const dataImportConfigurationGetActions = createAsyncAction(
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_REQUEST',
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_SUCCESS',
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_FAILURE',
  'integrations/dataImportConfiguration/system/GET_DATA_IMPORT_CONFIGURATION_CANCEL',
)<void, DataImportConfiguration | null, ApiError, string | void>();

export const dataImportConfigurationSaveActions = createAsyncAction(
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_REQUEST',
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_SUCCESS',
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_FAILURE',
  'integrations/dataImportConfiguration/system/SAVE_DATA_IMPORT_CONFIGURATION_CANCEL',
)<
  SaveDataImportConfigurationRequest,
  DataImportConfiguration | null,
  ApiError,
  string | void
>();

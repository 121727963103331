// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { SaveDataImportConfigurationRequest } from '@imprivata-cloud/adminapi-client';
import {
  dataImportConfigurationSelector,
  isLoadingSelector,
} from './selectors';
import {
  dataImportConfigurationGetActions,
  dataImportConfigurationSaveActions,
} from './actions';

export const useGetDataImportConfiguration = (): {
  dataImportConfiguration: ReturnType<typeof dataImportConfigurationSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const dataImportConfiguration = useSelector(dataImportConfigurationSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(dataImportConfigurationGetActions.request());

      return () => {
        dispatch(dataImportConfigurationGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return { dataImportConfiguration, isLoading: loading };
};

export const useSaveDataImportConfiguration = (
  request: SaveDataImportConfigurationRequest,
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dataImportConfigurationSaveActions.request(request));
  }, [dispatch, request]);
};

// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { Badge, Button, Empty, Skeleton, Table } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FilterFilled } from '@ant-design/icons';
import type {
  PatientDecrypted,
  PatientHl7MessageSummaryDecrypted,
} from 'src/api/types';
import type { TableProps } from 'antd';
import classes from './PatientHl7Messages.module.less';
import { usePatientSearch } from '../../../patient-search/store/hooks';
import { patientSearchHl7Messages } from '../../../patient-search/store/facades';
import {
  useFilteringAndSorting,
  usePatientDetailsPagination,
} from '../../store/hooks';
import { redirectWithQuery } from '../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../routers/route-names';

interface Hl7Message {
  key: string;
  date: string;
  status: string;
  ruleName: string;
  viewDetails: string;
}

interface Hl7MessagesTableProps {
  patient: PatientDecrypted | null;
  patientHl7Messages: PatientHl7MessageSummaryDecrypted[] | null;
  paginationComponent: JSX.Element;
  setLatestTableRecordDateTime: (dateTime: string) => void;
}

const PatientHl7MessagesTable: React.FC<Hl7MessagesTableProps> = ({
  patient,
  patientHl7Messages,
  paginationComponent,
  setLatestTableRecordDateTime,
}) => {
  const { t } = useTranslation();
  const { isLoadingHl7Messages } = usePatientSearch();
  const dispatch = useDispatch();
  const {
    pagination: paginationDetails,
    updatePagination,
    currentCount,
  } = usePatientDetailsPagination();

  const { filters, sorter, updateFilters, updateSorter } =
    useFilteringAndSorting({
      tableName: 'hl7Messages',
    });

  useEffect(() => {
    if (patient && patientHl7Messages == null) {
      patientSearchHl7Messages(
        patient.id ?? '',
        moment(patient.createdTimestamp) ?? moment(),
        1,
        100,
        dispatch,
      );
    }
  }, []);

  const data: Hl7Message[] =
    patientHl7Messages
      ?.map((message, index) => ({
        key: message.messageId ?? index.toString(),
        date:
          moment(message.createdTimestamp)?.format('YYYY-MM-DD HH:mm:ss') ?? '',
        status:
          (message.messageStatus?.at(0)?.toUpperCase() ?? '') +
          message.messageStatus?.slice(1),
        ruleName: message.eventDisplayName ?? '',
        viewDetails: 'View details',
      }))
      .sort(
        (m1, m2) => new Date(m2.date).getTime() - new Date(m1.date).getTime(),
      ) || [];

  useEffect(() => {
    setLatestTableRecordDateTime(data[0]?.date ?? '');
  }, [data]);

  const columns: TableProps<Hl7Message>['columns'] = [
    {
      title: () => (
        <span data-testid="patient-hl7-message-table--sortable-column--header-date">
          Date
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      sorter: {
        compare: (m1, m2) =>
          new Date(m1.date).getTime() - new Date(m2.date).getTime(),
      },
      sortOrder: sorter?.columnKey === 'date' ? sorter?.order : undefined,
      render: (_, record: Hl7Message) => {
        return (
          <span data-testid={`patient-hl7-message--date-${record.key}`}>
            {moment(record.date)?.format('YYYY-MM-DD HH:mm') ?? ''}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filteredValue: filters?.status || null,
      filters: data
        .filter(
          (currentOption, index, self) =>
            index ===
            self.findIndex(
              dataOption => dataOption.status === currentOption.status,
            ),
        )
        .map(message => ({
          text: message.status,
          value: message.status,
        })),
      filterSearch: true,
      filterIcon: filtered => (
        <FilterFilled
          data-testid="patient-hl7-message--status-filter"
          className={
            filtered ? classes.filterSelected : classes.filterUnselected
          }
        />
      ),
      onFilter: (value, record) => record.status === value,
      render: (_, record: Hl7Message) => {
        let styleClass: string;
        switch (record.status) {
          case 'Success':
            styleClass = classes.successBadge;
            break;
          case 'Error':
            styleClass = classes.errorBadge;
            break;
          case 'Unsent':
            styleClass = classes.unsentBadge;
            break;
          default:
            styleClass = classes.defaultBadge;
        }
        return (
          <Badge className={`${styleClass} ${classes.dotBadge}`}>
            <span
              data-testid={`patient-hl7-message--status-${record.key}`}
              className={classes.textBadge}
            >
              {record.status}
            </span>
          </Badge>
        );
      },
      ellipsis: true,
    },
    {
      title: 'Rule name',
      dataIndex: 'ruleName',
      key: 'ruleName',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: Hl7Message) => {
        return (
          <Button
            type="link"
            data-testid={`patient-hl7-message--rule-name-${record.key}`}
            onClick={onClickRuleName}
          >
            {record.ruleName}
          </Button>
        );
      },
    },
    {
      title: '',
      dataIndex: 'viewDetails',
      key: 'viewDetails',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      render: (_, record: Hl7Message) => {
        return (
          <Button
            type="link"
            data-testid={`patient-hl7-message--view-details-${record.key}`}
            onClick={() => onClickViewDetails(record)}
          >
            {record.viewDetails}
          </Button>
        );
      },
    },
  ];

  const onClickRuleName = () => {
    redirectWithQuery(integrationsRoutes.HL7_CONFIGURATION);
  };

  const onClickViewDetails = (record: Hl7Message) => {
    redirectWithQuery(integrationsRoutes.HL7_MESSAGES, [
      `startDate=${record.date}&endDate=${moment(record.date).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss')}&status=${record.status.toLowerCase()}&patientId=${patient?.id}`,
    ]);
  };

  return isLoadingHl7Messages ? (
    <Skeleton style={{ margin: '30px 0' }} active />
  ) : (
    <>
      {paginationComponent}
      <Table
        data-testid="patient-hl7-message--table"
        columns={columns}
        dataSource={data}
        onChange={(pagination, filters, sorter, extra) => {
          updateFilters(filters);
          updateSorter(sorter);
          updatePagination({
            ...paginationDetails,
            pageNumber:
              currentCount.totalCount !== extra.currentDataSource.length
                ? 1
                : paginationDetails.pageNumber,
            totalCount: extra.currentDataSource.length,
          });
        }}
        className={classes.antPagination}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          current: paginationDetails.pageNumber,
          pageSize: paginationDetails.pageSize,
          showSizeChanger: true,
          onChange: (pageNumber, pageSize) => {
            updatePagination({
              activeTab: '3',
              pageNumber,
              pageSize,
            });
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('patient-details.no-table-data')}
            />
          ),
        }}
        tableLayout="fixed"
      />
    </>
  );
};

export default PatientHl7MessagesTable;

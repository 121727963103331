// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configurationGetActions } from './actions';
import {
  isLoadingSelector,
  configurationSelector,
  errorSelector,
  configurationRangeConstantsSelector,
} from './selectors';

export const useGetConfiguration = (): {
  configuration: ReturnType<typeof configurationSelector>;
  configurationRangeConstants: ReturnType<typeof configurationRangeConstantsSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
  error: ReturnType<typeof errorSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const configuration = useSelector(configurationSelector);
  const configurationRangeConstants = useSelector(
    configurationRangeConstantsSelector,
  );
  const error = useSelector(errorSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(configurationGetActions.request());

      return () => {
        dispatch(configurationGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return {
    configuration,
    isLoading: loading,
    error,
    configurationRangeConstants,
  };
};

// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';
import { GetConfigurationResponse, SaveConfigRequest } from '@imprivata-cloud/adminapi-client';

export function saveConfiguration$(
  request: SaveConfigRequest,
): Observable<void> {
  return promiseToObservable(
    adminApiClient.saveConfig(request),
  );
}

export function getConfiguration$(): Observable<GetConfigurationResponse> {
  return promiseToObservable(adminApiClient.getConfig()).pipe(
    map((response: GetConfigurationResponse) => response),
  );
}

// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Col, Row, Image, Skeleton, Breadcrumb } from 'antd';
import { useEffect, useState } from 'react';
import type { PatientDecrypted } from '../../api/types';
import type { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import {
  PATIENT_DETAILS_ROUTE,
  PATIENT_SEARCH_ROUTE,
} from '../../routers/route-names';
import classes from './PatientDetailsContainer.module.less';
import PageLayout from '../../components/page-layout/PageLayout';
import NoPhotoSrc from '../../assets/svg/no-photo.svg';
import PatientDemographics from './components/patient-demographics/PatientDemographics';
import PatientDetailsTabsPanel from './components/patient-demographics/PatientDetailsTabsPanel';
import { usePatientSearch } from '../patient-search/store/hooks';
import { patientDelete } from './store/facades';
import {
  getPathWithQuery,
  redirectWithQuery,
} from '../../utils/routingHelpers';
import ConfirmActionModal from '../../components/confirm-modal/ConfirmActionModal';
import { getPatient$ } from '../../api/services/patientService';
import { endGetPatientSpan, startGetPatientSpan } from './tracing';
import {
  clearPatientDetailsAction,
  clearPatientSearchAction,
} from '../patient-search/store/actions';
import SkeletonLine from '../../components/skeleton-line/SkeletonLine';
import { showErrorBannerAction } from '../../store/error-banner-state/actions';
import { errors } from '../../store/error-banner-state/errors';
import SetTitle from '../../utils/DynamicTitleHelper';
import { usePatientDetailsPagination } from './store/hooks';
import PaginationControls from './components/general/PaginationControls';
import ContentCard from '../../components/content-card/ContentCard';
import PageSubHeader from '../../components/page-sub-header/PageSubHeader';

const PatientDetailsContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentSearchParams = new URLSearchParams(location.search);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { selectedPatient } = usePatientSearch();
  const [patient, setPatient] = useState<PatientDecrypted | null>(
    selectedPatient,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { pagination, currentCount, currentItems, updatePagination } =
    usePatientDetailsPagination();

  SetTitle(t('navigation.patient-details'));

  const localeDateString = (patient: PatientDecrypted | null) => {
    return patient ? moment(patient.createdTimestamp).format('YYYY-MM-DD') : '';
  };

  const toDataUri = (patient: PatientDecrypted | null) => {
    return patient ? 'data:image/jpeg;base64,' + patient.photo : '';
  };

  const onDeleteButtonClick = () => {
    setIsModalVisible(true);
  };

  const onCancelClick = () => {
    setIsModalVisible(false);
  };

  const performPatientDeletion = (patient: PatientDecrypted | null) => {
    patientDelete(patient?.id ? patient.id : '', dispatch);
    redirectWithQuery(PATIENT_SEARCH_ROUTE);
  };

  const onPageSizeChanged = (pageSize: number) => {
    updatePagination({
      ...pagination,
      pageSize,
      pageNumber: 1,
    });
  };

  const goToNextPage = () => {
    updatePagination({
      ...pagination,
      pageNumber: pagination.pageNumber + 1,
    });
  };

  const goToPreviousPage = () => {
    updatePagination({
      ...pagination,
      pageNumber: pagination.pageNumber - 1,
    });
  };

  const pageSizes: MenuItemType[] = [
    {
      key: '10',
      label: 10,
      onClick: () => {
        onPageSizeChanged(10);
      },
    },
    {
      key: '25',
      label: 25,
      onClick: () => {
        onPageSizeChanged(25);
      },
    },
    {
      key: '50',
      label: 50,
      onClick: () => {
        onPageSizeChanged(50);
      },
    },
    {
      key: '100',
      label: 100,
      onClick: () => {
        onPageSizeChanged(100);
      },
    },
  ];

  const useGetPatientEffect = () =>
    useEffect(() => {
      const patientId = currentSearchParams.get('patient');

      if (patientId && !patient) {
        setIsLoading(true);

        const promises: Promise<void>[] = [];
        if (!patient) {
          startGetPatientSpan();
          promises.push(
            getPatient$(patientId)
              .toPromise()
              .then(resultPatient => {
                endGetPatientSpan();
                setPatient(resultPatient);
              })
              .catch(err => {
                endGetPatientSpan(err);
                throw err;
              }),
          );
        }

        Promise.all(promises)
          .catch(err => {
            if (err.status === 404) {
              dispatch(
                showErrorBannerAction.request(
                  errors.PATIENT_DETAILS_GET_FAILED(),
                ),
              );
              redirectWithQuery(PATIENT_SEARCH_ROUTE);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  useGetPatientEffect();

  useEffect(() => {
    return () => {
      const nextPathname = history.location.pathname;
      if (nextPathname !== PATIENT_SEARCH_ROUTE) {
        dispatch(clearPatientSearchAction());
      } else {
        dispatch(clearPatientDetailsAction());
      }
    };
  }, [history, dispatch]);

  return (
    <PageLayout title={t('navigation.patient-details')}>
      <Layout>
        <Content>
          <Row style={{ marginBottom: '10px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link
                  to={getPathWithQuery(PATIENT_SEARCH_ROUTE)}
                  data-testid="patient-search-link"
                >
                  {t('patient-search.title')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t('navigation.patient-details')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <PageSubHeader title={t('navigation.patient-details')} />
          <ContentCard>
            <Row className={classes.patientRow} style={{ marginTop: '1.5rem' }}>
              <Col
                style={{
                  ...(isLoading
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '15em',
                        width: '15em',
                      }
                    : {}),
                }}
              >
                <Row
                  className={`${classes.imageRow} ${
                    isLoading ? classes.imageRowLoading : ''
                  }`}
                >
                  {isLoading ? (
                    <Skeleton.Image
                      active={true}
                      className={classes.patientPhotoSkeleton}
                    />
                  ) : (
                    <div className={classes.patientPhotoContainer}>
                      <Image
                        data-testid="patient-detail--photo"
                        className={classes.patientPhoto}
                        src={toDataUri(patient)}
                        fallback={NoPhotoSrc}
                      ></Image>
                      <span className={classes.patientPhotoLabel}>
                        Enrollment photo
                      </span>
                    </div>
                  )}
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '0.5rem',
                    }}
                  >
                    {isLoading ? (
                      <SkeletonLine style={{ width: '16rem' }} />
                    ) : (
                      `Captured ${localeDateString(patient)}`
                    )}
                  </Col>
                </Row>
              </Col>
              <Col style={{ paddingLeft: '1.875rem' }}>
                <PatientDemographics
                  onDeleteButtonClick={() => onDeleteButtonClick()}
                  patient={patient}
                  isLoading={isLoading}
                ></PatientDemographics>
              </Col>
            </Row>
          </ContentCard>
          <ContentCard>
            <Row className={classes.patientRow}>
              {isLoading ? (
                <Skeleton active={true} paragraph={{ rows: 4 }} />
              ) : (
                <PatientDetailsTabsPanel
                  patient={patient}
                  activities={currentItems.activities}
                  demographicUpdates={currentItems.demographicUpdates}
                  hl7Messages={currentItems.hl7Messages}
                  paginationComponent={
                    <PaginationControls
                      pageSizes={pageSizes}
                      currentCount={currentCount}
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      goToPreviousPage={goToPreviousPage}
                      goToNextPage={goToNextPage}
                      className={classes.paginationControls}
                    />
                  }
                />
              )}
            </Row>
          </ContentCard>
          <ConfirmActionModal
            title={t('patient-details.delete-patient-modal.title')}
            cancelText={t(
              'patient-details.delete-patient-modal.cancel-button-text',
            )}
            okText={t(
              'patient-details.delete-patient-modal.confirm-button-text',
            )}
            content={t('patient-details.delete-patient-modal.content')}
            visible={isModalVisible}
            onSave={() => performPatientDeletion(patient)}
            onCancel={() => onCancelClick()}
            onClose={() => onCancelClick()}
          ></ConfirmActionModal>
        </Content>
      </Layout>
    </PageLayout>
  );
};

export default PatientDetailsContainer;

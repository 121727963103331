// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import type {
  SaveDataImportConfigurationRequest,
  DataImportConfiguration,
  GetDataImportConfigurationResponse,
} from '@imprivata-cloud/adminapi-client';
import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';

export function saveDataImportConfiguration$(
  request: SaveDataImportConfigurationRequest,
): Observable<boolean> {
  return promiseToObservable(
    adminApiClient.saveDataImportConfiguration(request).finally(() => true),
  );
}

export function getDataImportConfiguration$(): Observable<DataImportConfiguration | null> {
  return promiseToObservable(adminApiClient.getDataImportConfiguration()).pipe(
    map(
      (response: GetDataImportConfigurationResponse) =>
        response.configuration ?? null,
    ),
  );
}

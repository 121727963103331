// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { RootAction } from '../../../../../store/rootAction';
import {
  configurationGetActions,
  configurationSaveActions,
} from './actions';
import { invalidSessionAction } from '../../../../login/store/actions';
import { Configuration, ConfigurationRangeConstants } from '../types';
import { GetConfigurationResponse, SaveConfigRequest } from '@imprivata-cloud/adminapi-client';

export interface ConfigurationState {
  loading: boolean;
  error: string | null;
  configuration: Configuration | null;
  configurationRangeConstants: ConfigurationRangeConstants | null;
}

export const initialState: ConfigurationState = {
  loading: false,
  error: null,
  configuration: null,
  configurationRangeConstants: null,
};

export const configurationReducer =
  combineReducers<ConfigurationState>({
    loading: createReducer<boolean>(initialState.loading)
      .handleAction(
        [
          configurationGetActions.request,
          configurationSaveActions.request,
        ],
        () => true,
      )
      .handleAction(
        [
          configurationGetActions.cancel,
          configurationGetActions.success,
          configurationGetActions.failure,
          configurationSaveActions.cancel,
          configurationSaveActions.success,
          configurationSaveActions.failure,
          invalidSessionAction.request,
        ],
        () => false,
      ),
    configuration: createReducer<Configuration | null>(
      initialState.configuration,
    )
      .handleAction(
        [configurationGetActions.success],
        (_: any, { payload }: { payload: GetConfigurationResponse }) => {
          var response = {
            minimumAge: 0,
            settingId: payload.settingId,
            minimumDataRetentionPeriod: 0
          }

          if (payload.settings) {
            for (const key in payload.settings) {
              if (payload.settings.hasOwnProperty(key)) {
                const setting = payload.settings[key];
                
                switch(key){
                  case 'minimumAge':
                    response.minimumAge = setting.value;
                    break;
                  case 'minimumDataRetentionPeriod':
                    response.minimumDataRetentionPeriod = setting.value;
                    break;
                }
              }
            }
          }

          return response;
        },
      )
      .handleAction(
        [configurationSaveActions.request],
        (_: any, { payload }: { payload: SaveConfigRequest }) => {
          var response = {
            minimumAge: 0,
            settingId: payload.settingId,
            minimumDataRetentionPeriod: 0
          }

          if(payload.settings) {
            for (const key in payload.settings) {
              if (payload.settings.hasOwnProperty(key)) {
                const setting = payload.settings[key];
  
                switch(key){
                  case 'minimumAge':
                    response.minimumAge = setting.value;
                    break;
                  case 'minimumDataRetentionPeriod':
                    response.minimumDataRetentionPeriod = setting.value;
                    break;
                }
              }
            }
          }

          return response;
        },
      )
      .handleAction([invalidSessionAction.request], () => null),
    configurationRangeConstants: createReducer<ConfigurationRangeConstants | null>(
      initialState.configurationRangeConstants,
    )
      .handleAction(
        [configurationGetActions.success],
        (_: any, { payload }: { payload: GetConfigurationResponse }) => {
          var response = {
            defaultMinimumAge: 0,
            minimumAgeRange: 0,
            maximumAgeRange: 0,
            defaultDataRetentionPeriod: 0,
            minimumDataRetentionPeriod: 0
          }

          if (payload.settings) {
            for (const key in payload.settings) {
              if (payload.settings.hasOwnProperty(key)) {
                const setting = payload.settings[key];
                
                switch(key){
                  case 'minimumAge':
                    response.defaultMinimumAge = setting.defaultValue;
                    break;
                  case 'minimumDataRetentionPeriod':
                    response.defaultDataRetentionPeriod = setting.defaultValue;
                    break;
                }
                
                // Accessing the validators
                if (setting.validators) {
                  for (const validatorKey in setting.validators) {
                    if (setting.validators.hasOwnProperty(validatorKey)) {
                      switch(validatorKey){
                        case 'minimumAgeRange':
                          response.minimumAgeRange = setting.validators[validatorKey];
                          break;
                        case 'maximumAgeRange':
                          response.maximumAgeRange = setting.validators[validatorKey];
                          break;
                        case 'minimumDataRetentionPeriod':
                          response.minimumDataRetentionPeriod = setting.validators[validatorKey];
                          break;
                      }
                    }
                  }
                }
              }
            }
          }

          return response;
        },
      )
      .handleAction([invalidSessionAction.request], () => null),
    error: createReducer<string | null, RootAction>(initialState.error)
      .handleAction(
        [
          configurationGetActions.request,
          configurationGetActions.success,
          configurationSaveActions.request,
          configurationSaveActions.success,
          invalidSessionAction.request,
        ],
        () => null,
      )
      .handleAction(
        [
          configurationGetActions.failure,
          configurationSaveActions.failure,
        ],
        (_, { payload }) => payload.code || null,
      )
      .handleAction(
        [
          configurationGetActions.cancel,
          configurationSaveActions.cancel,
        ],
        () => 'cancelled',
      ),
  });

// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, switchMap, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../../../i18n';
import {
  dataImportConfigurationGetActions,
  dataImportConfigurationSaveActions,
} from './actions';
import { getErrorMessageCode } from '../../../../../i18n/utils';
import {
  getDataImportConfiguration$,
  saveDataImportConfiguration$,
} from '../../../../../api/services/dataImportConfigurationService';
import { redirectWithQuery } from '../../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../../routers/route-names';
import {
  endGetDataImportConfigurationSpan,
  endSaveDataImportConfigurationSpan,
  startGetDataImportConfigurationSpan,
  startSaveDataImportConfigurationSpan,
} from '../../../tracing';
import { showErrorBannerAction } from '../../../../../store/error-banner-state/actions';
import { errors } from '../../../../../store/error-banner-state/errors';

export const saveDataImportConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(dataImportConfigurationSaveActions.request)),
    tap(_ => {
      startSaveDataImportConfigurationSpan();
    }),
    switchMap(({ payload }) => {
      return from(saveDataImportConfiguration$(payload)).pipe(
        tap(() => {
          endSaveDataImportConfigurationSpan();
          redirectWithQuery(integrationsRoutes.DATA_IMPORT);
        }),
        map(() => dataImportConfigurationSaveActions.success(payload)),
        catchError(error => {
          endSaveDataImportConfigurationSpan(error);
          return of(
            dataImportConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.DATA_IMPORT,
                'data-import-configuration--save-failed',
              ),
              message:
                'An error occurred while saving the system configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.DATA_IMPORT_CONFIGURATION_SAVE_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const getDataImportConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(dataImportConfigurationGetActions.request)),
    tap(() => {
      startGetDataImportConfigurationSpan();
    }),
    switchMap(() => {
      return from(getDataImportConfiguration$()).pipe(
        tap(() => {
          endGetDataImportConfigurationSpan();
        }),
        map(configuration =>
          dataImportConfigurationGetActions.success(configuration),
        ),
        catchError(error => {
          endGetDataImportConfigurationSpan(error);
          return of(
            dataImportConfigurationGetActions.failure({
              code: getErrorMessageCode(
                ContextNames.DATA_IMPORT,
                'data-import-configuration--system--get-failed',
              ),
              message:
                'An error occurred while retrieving the system configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.DATA_IMPORT_CONFIGURATION_GET_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const dataImportConfigurationEpic = combineEpics(
  saveDataImportConfigurationEpic,
  getDataImportConfigurationEpic,
);

// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { DataImportConfiguration } from '@imprivata-cloud/adminapi-client';
import type { RootState } from '../../../../../store/rootReducer';

export const isLoadingSelector = ({
  dataImportConfiguration,
}: RootState): boolean => dataImportConfiguration.loading;

export const dataImportConfigurationSelector = ({
  dataImportConfiguration,
}: RootState): DataImportConfiguration | null =>
  dataImportConfiguration.configuration;

// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { Badge, Empty, Skeleton, Table, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FilterFilled } from '@ant-design/icons';
import type { ActivityDecrypted, PatientDecrypted } from 'src/api/types';
import type { TableProps } from 'antd';
import classes from './PatientActivityHistory.module.less';
import { usePatientSearch } from '../../../patient-search/store/hooks';
import { patientActivityHistory } from '../../../patient-search/store/facades';
import {
  useFilteringAndSorting,
  usePatientDetailsPagination,
} from '../../store/hooks';
import { formatActivitySubtype } from '../../../../utils/utils';

interface Activity {
  key: string;
  date: string;
  activity: string;
  result: string;
  machine: string;
  user: string;
}

interface ActivityHistoryTableProps {
  patient: PatientDecrypted | null;
  patientActivities: ActivityDecrypted[] | null;
  paginationComponent: JSX.Element;
  setLatestTableRecordDateTime: (dateTime: string) => void;
}

const PatientActivityHistoryTable: React.FC<ActivityHistoryTableProps> = ({
  patient,
  patientActivities,
  paginationComponent,
  setLatestTableRecordDateTime,
}) => {
  const { t } = useTranslation();
  const { isLoadingActivityHistory } = usePatientSearch();
  const dispatch = useDispatch();
  const {
    pagination: paginationDetails,
    updatePagination,
    currentCount,
  } = usePatientDetailsPagination();

  const { filters, sorter, updateFilters, updateSorter } =
    useFilteringAndSorting({
      tableName: 'activities',
    });

  useEffect(() => {
    if (patient && patientActivities == null) {
      patientActivityHistory(patient.id ?? '', dispatch);
    }
  }, []);

  const data: Activity[] =
    patientActivities
      ?.map((activity, index) => ({
        key: index.toString(),
        date: moment(activity.date)?.format('YYYY-MM-DD HH:mm:ss') ?? '',
        activity: activity.activityType ?? '',
        result: formatActivitySubtype(activity.activitySubtype ?? ''),
        machine:
          activity.createdAt && activity.createdAt !== ''
            ? activity.createdAt
            : 'N/A',
        user:
          activity.createdBy && activity.createdBy !== ''
            ? activity.createdBy
            : 'N/A',
      }))
      .sort(
        (a1, a2) => new Date(a2.date).getTime() - new Date(a1.date).getTime(),
      ) || [];

  useEffect(() => {
    setLatestTableRecordDateTime(data[0]?.date ?? '');
  }, [data]);

  const columns: TableProps<Activity>['columns'] = [
    {
      title: () => (
        <span data-testid="patient-activity-history-table--sortable-column--header-date">
          Date
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      sorter: {
        compare: (a1, a2) =>
          new Date(a1.date).getTime() - new Date(a2.date).getTime(),
        multiple: 3,
      },
      sortOrder: sorter?.columnKey === 'date' ? sorter?.order : undefined,
      render: (_, record: Activity) => {
        return (
          <span data-testid={`patient-activity-history--date-${record.key}`}>
            {moment(record.date)?.format('YYYY-MM-DD HH:mm') ?? ''}
          </span>
        );
      },
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filteredValue: filters?.activity || null,
      filters: data
        .filter(
          (currentOption, index, self) =>
            index ===
            self.findIndex(
              dataOption => dataOption.activity === currentOption.activity,
            ),
        )
        .map(activity => ({
          text: activity.activity,
          value: activity.activity,
        })),
      filterSearch: true,
      filterIcon: filtered => (
        <FilterFilled
          data-testid="patient-activity-history--activity-filter"
          className={
            filtered ? classes.filterSelected : classes.filterUnselected
          }
        />
      ),
      onFilter: (value, record) => record.activity === value,
      render: (_, record: Activity) => {
        let styleClass: string;
        switch (record.activity) {
          case 'Enrollment':
            styleClass = classes.enrollmentTag;
            break;
          case 'Verification':
            styleClass = classes.verificationTag;
            break;
          case 'Identification':
            styleClass = classes.identificationTag;
            break;
          default:
            styleClass = classes.defaultTag;
        }
        return (
          <Tag
            data-testid={`patient-activity-history--activity-${record.key}`}
            className={`${styleClass} ${classes.activityTag}`}
          >
            {record.activity}
          </Tag>
        );
      },
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filteredValue: filters?.result || null,
      filters: data
        .filter(
          (currentOption, index, self) =>
            index ===
            self.findIndex(
              dataOption => dataOption.result === currentOption.result,
            ),
        )
        .map(activity => ({ text: activity.result, value: activity.result })),
      filterSearch: true,
      filterIcon: filtered => (
        <FilterFilled
          data-testid="patient-activity-history--result-filter"
          className={
            filtered ? classes.filterSelected : classes.filterUnselected
          }
        />
      ),
      onFilter: (value, record) => record.result === value,
      render: (_, record: Activity) => {
        let styleClass: string;
        switch (record.result) {
          case 'Success':
            styleClass = classes.successBadge;
            break;
          case 'Failed':
            styleClass = classes.failedBadge;
            break;
          case 'Multiple matches success':
          case 'Success - duplicate found':
          case 'Success - multiple matches found':
            styleClass = classes.multipleMatchesBadge;
            break;
          default:
            styleClass = classes.defaultBadge;
        }
        return (
          <Badge className={`${styleClass} ${classes.dotBadge}`}>
            <span
              data-testid={`patient-activity-history--result-${record.key}`}
              className={classes.textBadge}
            >
              {record.result}
            </span>
          </Badge>
        );
      },
      ellipsis: true,
    },
    {
      title: 'Machine',
      dataIndex: 'machine',
      key: 'machine',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filteredValue: filters?.machine || null,
      filters: data
        .filter(
          (currentOption, index, self) =>
            index ===
            self.findIndex(
              dataOption => dataOption.machine === currentOption.machine,
            ),
        )
        .map(activity => ({ text: activity.machine, value: activity.machine })),
      filterSearch: true,
      filterIcon: filtered => (
        <FilterFilled
          data-testid="patient-activity-history--machine-filter"
          className={
            filtered ? classes.filterSelected : classes.filterUnselected
          }
        />
      ),
      onFilter: (value, record) => record.machine === value,
      render: (_, record: Activity) => {
        return (
          <span data-testid={`patient-activity-history--machine-${record.key}`}>
            {record.machine}
          </span>
        );
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filteredValue: filters?.user || null,
      filters: data
        .filter(
          (currentOption, index, self) =>
            index ===
            self.findIndex(
              dataOption => dataOption.user === currentOption.user,
            ),
        )
        .map(activity => ({ text: activity.user, value: activity.user })),
      filterSearch: true,
      filterIcon: filtered => (
        <FilterFilled
          data-testid="patient-activity-history--user-filter"
          className={
            filtered ? classes.filterSelected : classes.filterUnselected
          }
        />
      ),
      onFilter: (value, record) => record.user === value,
      render: (_, record: Activity) => {
        return (
          <span data-testid={`patient-activity-history--user-${record.key}`}>
            {record.user}
          </span>
        );
      },
    },
  ];

  return isLoadingActivityHistory ? (
    <Skeleton style={{ margin: '30px 0' }} active />
  ) : (
    <>
      {paginationComponent}
      <Table
        data-testid="patient-activity-history--table"
        columns={columns}
        dataSource={data}
        onChange={(pagination, filters, sorter, extra) => {
          updateFilters(filters);
          updateSorter(sorter);
          updatePagination({
            ...paginationDetails,
            pageNumber:
              currentCount.totalCount !== extra.currentDataSource.length
                ? 1
                : paginationDetails.pageNumber,
            totalCount: extra.currentDataSource.length,
          });
        }}
        className={classes.antPagination}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          current: paginationDetails.pageNumber,
          pageSize: paginationDetails.pageSize,
          showSizeChanger: true,
          onChange: (pageNumber, pageSize) => {
            updatePagination({
              activeTab: '1',
              pageNumber,
              pageSize,
            });
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('patient-details.no-table-data')}
            />
          ),
        }}
        tableLayout="fixed"
      />
    </>
  );
};

export default PatientActivityHistoryTable;
